<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Venue Hires Calendar</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="grey lighten-3"
              class="mr-2"
              :to="{ name: 'module-craigtoun-venues-list-all' }"
            >
              <v-icon left>mdi-format-list-bulleted-square</v-icon>
              List View
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="orange white--text"
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues-seasons' }"
                >
                  <v-icon small>mdi-weather-partly-cloudy</v-icon>
                </v-btn>
              </template>
              <span>View Seasons</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="blue white--text"
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues-list' }"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View Venues</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.eventDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Hire</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title class="ml-4" v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-toolbar-title class="ml-4" v-else>
            {{ currentMonth }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          :event-height="45"
          @click:event="showEvent"
          @click:more="viewDay"
        >
          <template v-slot:event="{ event }">
            <div class="py-1 px-1">
              {{ event.name }}<br />
              {{ event.customer_name }}
            </div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="$refs.eventDialog.open(selectedEvent)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div>Customer: {{ selectedEvent.customer_name }}</div>
              <div>Start Time: {{ selectedEvent.start_time }}</div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Close
              </v-btn>
              <v-btn color="error" @click="openDelete(selectedEvent)">
                Cancel Booking
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-dialog v-model="deleteBooking.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Cancel Booking</v-card-title>
          <v-card-text
            >Are you sure you wish to cancel this booking?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" depressed @click="resetDelete">No</v-btn>
            <v-btn
              color="success"
              depressed
              :loading="deleteBooking.loading"
              @click="saveDelete"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <event-dialog ref="eventDialog" />
  </div>
</template>

<script>
import EventDialog from "./components/EventDialog";
import moment from "moment";

export default {
  components: {
    EventDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: true,
        },
        {
          text: "Calendar",
          disabled: true,
        },
      ],
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      currentMonth: moment().format("MMMM YYYY"),
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
    };
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  computed: {
    events() {
      let bookings = this.$store.getters["craigtoun/venuesStore/bookingsAll"];

      bookings.map((item) => {
        item.start = new Date(item.start);
        item.color = item.venue.color;
        item.name = item.venue.name;
      });

      return bookings;
    },
  },

  methods: {
    openDelete(booking) {
      this.deleteBooking.booking = booking;
      this.deleteBooking.dialog = true;
    },

    resetDelete() {
      this.deleteBooking.dialog = false;
      this.deleteBooking.booking = {};
      this.deleteBooking.loading = false;
    },

    saveDelete() {
      this.deleteBooking.loading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/deleteBooking", {
          appId: this.$route.params.id,
          bookingId: this.deleteBooking.booking.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },

    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    setToday() {
      this.focus = "";
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
  },
};
</script>
